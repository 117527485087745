<template>
<div>
    <div id="controls">   
        <div class="row justify-content-start control-panel-wrapper">
            <div class="col-4 control-panel">
                    <div class="row justify-content-start">
                        <div class="col-1"></div>
                        <div class="col-6 toggle-pages-wrapper">
                            <div :class="{'owl-prev': true, 'disabled': !isPrevPageAvailable}" @click="prevPage"></div>
                            <input class="page-number" @change="goToPage" v-model.number="currentPage" v-if="isPrevPageAvailable || isNextPageAvailable" type="number"/>
                            <div :class="{'owl-next': true, 'disabled': !isNextPageAvailable}" @click="nextPage"></div>
                        </div>
                        <div class="col-4 zoom-outer-wrapper">
                            <div class="zoom-wrapper">
                                <div :class="{zoom: true, 'zoom-in':true, 'zoom-inactive': !canZoomIn || isOnRendering}" @click="zoomIn"></div>
                                <div class="percents">{{Math.round((1 - 1 + this.zoom) * 100)}}%</div>
                                <div :class="{zoom: true, 'zoom-out': true, 'zoom-inactive': !canZoomOut || isOnRendering}" @click="zoomOut"></div>

                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
        <div id="preload" v-if="isOnRendering" class="loader"></div>
    <div class="pages">

        <div v-for="(page, i) in pages" :key="i" class="page">
            <canvas :id="'page-' + page"></canvas>
        </div>
    </div>
</div>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist'
import controls from './ControlsBar'
export default {
    components: {
        controls
    },
    props: {
        'url': String
    },
    data() {
        return {
            url: this.url,
            downloadedPdf: null,
            pdfDocment: null,
            currentPage: 1,
            pagesTotal: 0,
            pages: [],
            width: 500,
            zoom: 1,
            scale: 0,
            maxZoomOffset: 0.8,
            zoomStep: 0.2,
            isOnRendering: true,
            pageToJump: {next: null, prev: null, quantity: null}
        }
    },
    computed: {
        isNextPageAvailable() { return this.currentPage + 1 < this.pagesTotal ? true:false; },
        isPrevPageAvailable() { return this.currentPage - 1 > 0 ? true: false; } ,
        canZoomIn(){return Math.abs(this.zoom + this.zoomStep - 1) <= this.maxZoomOffset},
        canZoomOut(){return Math.abs(this.zoom - this.zoomStep - 1) <= this.maxZoomOffset},
        isCurrentlyRendering(){return this.isOnRendering}
    },
    created() { pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/build/pdf.worker.js'; },
    mounted() {
        this.scale = 0.4883928571428571
        // Asynchronous download of PDF
        this.downloadedPdf = pdfjsLib.getDocument(this.url);
        this.downloadedPdf.promise.then((pdf) => {
            this.pdfDocment = pdf
            this.pagesTotal = this.pdfDocment.numPages
            for (let i = 1; i < this.pagesTotal; i++) {
                this.pages.push(i)
            }           
        }, function (reason) {
            // PDF loading error
            console.error(reason);
        }).finally(() => {
                this.renderAll()
        })
        this.handleKeyboardPageChange()
    },
    methods: {
        handleKeyboardPageChange(){
            $(document).keydown((e) => {
                const LEFT = 37
                const RIGHT = 39

                switch(e.which){
                    case LEFT: this.prevPage(40)
                        break;
                    case RIGHT: this.nextPage(40)
                        break;
                }
            })
        },
        initCanvas(){
            this.pages = []

            this.renderAll()  
        },
        renderAll(){
            this.isOnRendering = true

            this.pages.forEach(index => {
                this.getPage(index, document.getElementById('page-' + index), index === this.pages.length - 1)                
            });            
        },
        renderPage(pageNumber, canvas, isLast) {
            this.pdfDocment.getPage(pageNumber).then((page) => {
                const viewport = page.getViewport({scale: this.scale + this.zoom})                

                this.width = viewport.width
                canvas.height = viewport.height 
                canvas.width = viewport.width 

                page.render({canvasContext: canvas.getContext('2d'), viewport}).promise.then(() => {
                    $(canvas).css('height', viewport.height + 'px')
                    $(canvas).css('width', viewport.width + 'px')
                    if (isLast){                        
                        this.isOnRendering = false
                    }
                });
            });
        },
        getPage(pageId, canvas, isLast = false) { if (pageId > 0 && pageId < this.pagesTotal) this.renderPage(pageId, canvas, isLast) },
    handleControlsAction() {},
        nextPage(animationSpeed = 200) {
            if(this.isNextPageAvailable){
                this.scrollPage(++this.currentPage, animationSpeed)
            }
        },
        prevPage(animationSpeed = 200) {
            if(this.isPrevPageAvailable){
                this.scrollPage(--this.currentPage, animationSpeed)
            }
        },
        scrollPage(index, animationSpeed){
            const width = (index - 1) * $('.page').width()
                $('body').animate({scrollLeft: width - 40}, animationSpeed)
        },
        goToPage(){
            this.validateCurrentPage()
            this.scrollPage(this.currentPage)
        },
        validateCurrentPage(){
            let validated = true
            if(typeof this.currentPage !== 'number'){
                this.currentPage = 1
                validated = false
            }
            if (this.currentPage > this.pagesTotal){
                this.currentPage = this.pagesTotal
                validated = false
            }else if (this.currentPage < 1){
                this.currentPage = 1
                validated = false
            }
            return validated
        },
        zoomIn(){
            if (this.canZoomIn && !this.isOnRendering){
                this.zoom += this.zoomStep
                this.renderAll()
            }
        },        
        zoomOut(){
            if (this.canZoomOut && !this.isOnRendering){
                this.zoom -= this.zoomStep
                this.renderAll()
            }
        }
    }
}
</script>

<style scoped>    
    :root{
        --controls-height: 50px;
    }
    #controls {
        position: fixed;
        height: var(--controls-height);
        background-color: #ebf5ef;
        color: black;
        width: 100%;
        top: 0px;
    }
    .control-panel-wrapper{
        padding: 5px;
    }
    .control-panel{
        max-width: 400px !important;
    }
    .toggle-pages-wrapper {
        display: flex;
    }
    .toggle-pages-wrapper div{
        margin: 5px;
    }
    .pages {
        width: fit-content;
        z-index: -1;
        display: flex;
        justify-content: center;
        margin: calc(var(--controls-height, 50px) + 15px) 0 15px 0;
        height: fit-content;
    }
    .page {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        width: 100vw;
        height: fit-content;
    }
    .page-number{
        width: 60px;
        height: 60%;
        display: block;
        margin: auto;
    }
    .zoom-outer-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .zoom-wrapper{
        display: flex;
        min-width: 90px;
        width: 100%;
        max-width: 120px;
        justify-content: space-between;
    }
    .percents{
        margin: 0 5px;
    }
    .zoom{
        width: 25px;
        height: 25px;
        background-size: cover !important;
    }
    .zoom:hover{
        cursor: pointer;
    }
    .zoom-in{
        background: url(/images/zoom-in.png) 0 0 no-repeat;
    }
    .zoom-out{
        background: url(/images/zoom-out.png) 0 0 no-repeat;
    }
    .zoom-inactive{
        opacity: 0.5 !important;
    }
    #preload{
            position: relative;
        top: 40vh;
    }
</style>
