class DistributorLineBreaker {
    constructor($elements) {
        this.elements = $elements
    }
    insertLineBreak($element, lineBreakToInsertIndex) {
    const wordParts = $element.text().split(/(!<,|@|\.|\/\/|:)/)

    if (wordParts.length > 0 && lineBreakToInsertIndex > 0 && lineBreakToInsertIndex < wordParts.length) {
        if (lineBreakToInsertIndex - 1 > 0) {
            $element.html(wordParts.splice(0, lineBreakToInsertIndex - 1).join('') + "<br>" + wordParts.join(''))
        } else {
            $element.html(wordParts.splice(0, 1).join('') + "<br>" + wordParts.join(''))

        }

        return true
    }
        return false
    }

    clearLineBreaks($element) {
        return $element.html($element.text().replace('< br/>', ''))
    }

     countLineBreaksMax($element) {
        return $element.text().split(/(!<,|@|\.|\/\/|:)/).length
    }

     isLineBreakNeeded($element) {
        return $element.width() > $element.parent().width()
    }

     fitTextIntoParentWidth($element) {
         const lineBreaksMax = this.countLineBreaksMax($element)
        let lineBreakToInsertIndex = lineBreaksMax - 1

         while (this.isLineBreakNeeded($element) && lineBreakToInsertIndex > 0) {
            this.clearLineBreaks($element)
            if (!this.insertLineBreak($element, lineBreakToInsertIndex--)) {
                break
            }
        }
     }
    main() {
        this.elements.length > 0 && this.elements.each(index => this.fitTextIntoParentWidth($(this.elements[index])))
    }
}

const lineBreaker = new DistributorLineBreaker($('.distributor-slider-bottom__item_site a, .distributor-slider-bottom__item_mail a')) 

$(document).ready(() => lineBreaker.main())
$(window).resize(() => lineBreaker.main())
