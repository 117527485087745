<template>
    <div id="controls">
        <div class="row justify-content-center">
            <div class="col-2 toggle-pages-wrapper">
                <div class="owl-prev" v-if="isNextPageAvailable" @click="nextPage"></div>
                <div class="owl-next" v-if="isPrevPageAvailable" @click="prevPage"></div>
            </div>            
        </div>
    </div>
</template>

<script>
export default {
    name: 'controls',
    props: {
    },
    data() {
        return {
            
        }
    },    
    methods: {
        nextPage(){
            this.$store.commit('reader/getNextPage')
        },
        prevPage(){
            this.$store.commit('reader/getPrevPage')
        }
    },
    computed: {
        isNextPageAvailable(){
            return true;
        },
        isPrevPageAvailable(){
            return true;
        }
    },

}
</script>

<style scoped>

#controls{
    position: fixed;
    height: 50px;
    background-color: #ebf5ef;
    color: black;
    width: 100%;
}
.toggle-pages-wrapper{
    display: flex;
}
</style>
