<template>
<form @submit="checkForm" method="post" id="form-adjuvants" :action="query">
    <input type="hidden" name="_token" :value="protection">
                    <input name="calc_type" type="hidden" value="adjuvants">
                    <input name="locale" id="locale" type="hidden" :value="locale">
                        <div class="expert-calc__title">{{calcTitle}}</div>
                        <div class="select-wr hidden-select expert-form-city">                                                       
                            <div class="input-wr hide-label">
                                <label for="f4">{{waterInitialTitle}}</label>
                                <input name="water_initial" class="custom-input" @change="handleWaterInput" v-model="waterInit">
                                <div class="custom-controls" data-step="0.1" data-min="5" data-max="9.5">
                                    <span @click.prevent="addInitialWater" class="custom-plus"></span>
                                    <span @click.prevent="substrInitialWater" ></span>
                                </div>
                                <div  v-if="waterInitError.length != 0" class="error-custom">{{waterInitError}}</div>
                            </div>    
                        </div>
                        <div class="select-wr hidden-select expert-form-city">                                                       
                            <div class="input-wr  hide-label">
                                <label for="f4">{{waterTerminalTitle}}</label>
                                <input name="water_terminal" class="custom-input" @change="handleWaterTerminalInput" v-model="waterTerminal">
                                <div class="custom-controls"  data-step="0.1" data-min="5" data-max="9.5">
                                    <span @click.prevent="addTerminalWater" class="custom-plus"></span>
                                    <span @click.prevent="substrTerminalWater" ></span>
                                </div>
                                <div  v-if="waterTerminalError.length != 0" class="error-custom">{{waterTerminalError}}</div>
                            </div>    
                        </div>                
                        <div class="select-wr hidden-select expert-form-city">                                                       
                            <div class="input-wr  hide-label">
                                <label for="f4">{{alkalinityTitle }}</label>
                                <input name="alkalinity" class="custom-input" @change="handleAlkalinityInput" v-model="alkalinity">
                                    <div class="custom-controls">
                                        <span @click.prevent="addAlkalinity" class="custom-plus"></span>
                                        <span@click.prevent="substrAlkalinity" ></span>
                                    </div>
                                    <div v-if="alkaError.length != 0" class="error-custom">{{alkaError}}</div>
                            </div>                                
                        </div>                        
                        <div class="button-wr" style="position: relative;">
                            <button style="display: block; margin: 0 auto;" type="submit" click="submitCalc">{{evaluateTitle}}</button>
                        </div>                       
                    </form>
</template>

<script>
export default {
    name:'expert-calc',
    props: [
        'locale',
        'waterInitialTitle',
        'waterTerminalTitle', 
        'calcTitle',
        'alkalinityTitle',
        'waterInitialValue',
        'waterTerminalValue',
        'alkalinityValue',
        'evaluateTitle',
        'waterErrMsg',
        'alkaErrMsg',
        'query',
        'csrf'
    ],
    mounted() {     
    },
    methods: {
        handleWaterInput(){            
            this.waterInitError = (this.isWaterValid(this.waterInit) && this.isBetweenRange(this.waterInit, 5, 9.5)) ? '' : this.waterErrMsg
            if(this.isWaterValid(this.waterInit)){
                this.waterInit = Number(this.waterInit)
            }                        
        },
        handleWaterTerminalInput(){
            this.waterTerminalError = (this.isWaterValid(this.waterTerminal) && this.isBetweenRange(this.waterTerminal, 5, 9.5)) ? '' : this.waterErrMsg
            if(this.isWaterValid(this.waterTerminal)){
                this.waterTerminal = parseFloat(this.waterTerminal)
            }
        }
        ,handleAlkalinityInput(){
            this.alkaError = (this.isAlkalinityValid(this.alkalinity) && this.isBetweenRange(this.alkalinity, 0, 30)) ? '' : this.alkaErrMsg
            if(this.isAlkalinityValid(this.alkalinity)){
                this.alkalinity = Number(this.alkalinity)
            }
        },
        addInitialWater(){
            const test =    this.isWaterValid(this.waterInit)
            const test1 =   this.isWaterValid(this.waterTerminal)
            const test2 = this.changeValueInBetween(this.waterInit, 5.0, 9.5, 0.1)
            if (test && test1){
                this.waterInit = this.changeValueInBetween(this.waterInit, 5.0, 9.5, 0.1)
            }
        },
        addTerminalWater(){
            if (this.waterValidator.test(this.waterTerminal)){
                const test =  this.changeValueInBetween(this.waterTerminal, 5.0, 9.5,0.1)
                this.waterTerminal = this.changeValueInBetween(this.waterTerminal, 5.0, 9.5,0.1)
            }
            
        },
        addAlkalinity(){
            if (this.isAlkalinityValid(this.alkalinity)){
                this.alkalinity = this.changeValueInBetween(this.alkalinity, 0, 30, 0.01)
            }            
        },
        substrTerminalWater(){
            if (this.isWaterValid(this.waterTerminal)){
                this.waterTerminal = this.changeValueInBetween(this.waterTerminal, 5.0,  9.5, -0.1, true)
            }
        },
        substrInitialWater(){
            if (this.isWaterValid(this.waterInit)){
                this.waterInit = this.changeValueInBetween(this.waterInit, 5.0,  9.5, -0.1, true)
            }
        },
        substrAlkalinity(){
            if(this.isAlkalinityValid(this.alkalinity)){
                this.alkalinity = this.changeValueInBetween(this.alkalinity, 0, 30, -0.01, true)
            }
        },
        handleNumberInputs(){
            $('.custom-controls').on ('click', function (e){
                const clickedToAdd = $(e.target).hasClass('custom-plus') ? true : false
                const deltaValue = clickedToAdd ? Number($(this).attr('data-step')) : -1 * Number($(this).attr('data-step'))
                const min = Number($(this).attr('data-min'))
                const max = Number($(this).attr('data-max'))
                const input = $(this).prev()
                const currentValue = Number(input.val())
                const newSum = currentValue + deltaValue
                
            })
        },
        checkForm(){
            if (!this.thereIsNoErrors()){
                e.preventDefault()
            }else{
                this.errorsFound = true
            }
        },
        //* ИМПЛЕМЕНТАЦИЯ, ФУНКЦИ ДЛЯ 'ГРЯЗНОЙ' РАБОТЫ
        changeValueInBetween(currentValue, min,max, delta, minus = false){
            let resultValue = currentValue
            if (this.isBetweenRange(currentValue + delta, min,max)){
                resultValue = minus ? Math.floor((currentValue + delta) * 100) / 100:Math.ceil((currentValue + delta) * 100) / 100
            }
            return resultValue
        },
        isBetweenRange(number, min, max){
            if (number <= max && number >= min){
                    return true
            }
            return false
        },
        isWaterValid(string){
            const test = this.waterValidator.test(string)
            return this.waterValidator.test(string)
        },
        isAlkalinityValid(string){
            return this.alkalinityValidator.test(string)
        },
        thereIsNoErrors(){
            if (this.waterInitError.length != 0 &&
                this.waterTerminalError.length != 0 &&
                this.alkaError.length != 0){
                    return false
                }
                return true
        },
        terminalValueLessThanInitial() {return this.waterInit < this.waterTerminal},
        
    },
    computed:  {
        
        
    },
    data: function () {
        return {        
            waterInit: this.waterInitialValue,
            waterTerminal: this.waterTerminalValue,
            alkalinity: this.alkalinityValue,
            waterValidator: new RegExp(/^[5-9](\.\d{1,2}){0,1}$/),
            alkalinityValidator: new  RegExp(/^[0-3]{0,1}[0-9]{0,1}?(\.\d{1,3}){0,1}$/),
            waterInitError: '', 
            waterTerminalError: '', 
            alkaError: '',
            commonErrors: [],
            errorsFound: false,
            protection: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    },
    computed: {
    },
};
</script>

<style scoped>
.input-wr span:before {
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 3px;
    left: 2px;
    content: "\E903";
    color: #3d3d3d;
    font-size: 7px;
    width: 11px;
    height: 7px;
}

/* .input-wr .custom-plus:before{
    transform: rotate(180deg);
} */
.hidden-select:before{
    display: none !important;
}
.form-has-errors{
    background-color: silver;
}
@media screen and (max-width: 994px) {
    .input-wr span:before {
        font-size: 4px;
    }
}
</style>
