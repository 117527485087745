/**
 * First we will load all of state.data project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./jquery-2.2.0.min');
require('./bootstrap');
require('./new-owl/owl.carousel');
require('./new-owl/owl.autoplay');
require('./new-owl/owl.navigation');
require('./new-owl/owl.support');
require('./new-owl/owl.autoheight');
// require('@ckeditor/ckeditor5-build-classic/build/ckeditor');
// require('@ckeditor/ckeditor5-vue/dist/ckeditor');
window.Vue = require('vue');
var VueScrollTo = require('vue-scrollto');
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
require('./modules/_burger_menu');
require('./modules/_script-new');
require('./modules/_sliders-new');
require('./modules/distrubutors-slider-fix');
//require('./modules/company-rombus-hover-hold');
require('./script.js');
require('./sliders-3.js')
require('./map.js');
require('pdfjs-dist')

Vue.component('dist-tabs', require('./components/Distributors/DistributorTabs').default)
Vue.component('expert-calc', require('./components/Expert/ExpertInput').default)
Vue.component('pdf-reader', require('./components/Reader/Reader').default)
Vue.component('controls-bar', require('./components/Reader/ControlsBar').default)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 **/
// Import and use Vue Froala lib.

//import VueFroala from 'vue-froala-wysiwyg'
import * as axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
Vue.use(VueScrollTo);

VueScrollTo.setDefaults({
    duration: 500,
    lazy: false,
    easing: "ease",
    offset: -150,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})
const store = new Vuex.Store({
    state: {
        tagsLock: {}
    },
    mutations: {
        handleFiltered_blog(state, data) {
            state.blogsSearch.blogs.blogsOveralLength = data.length
            state.blogsSearch.blogs.addBlogs(data)
            state.blogsSearch.blogs = state.blogsSearch.blogs.copy(state.blogsSearch.blogs)
        },
        handleFiltered__blog_tag(state, data) {
            if (data.length !== 0) {
                state.blogsTags.addBlogItems(data.blogs)
                state.blogsTags = state.blogsTags.copy(state.blogsTags)
                state.blogsTagsMeta.add = data
            }
        },
        clearSearchVideos(state) {
            state.blogsSearch.blogs.clear()
            state.blogsSearch.blogs = state.blogsSearch.blogs.copy(state.blogsSeacrh.blogs)
        },
        setTagsLock(state, data) {
            state.tagsLock[data.id] = data.state
            state.tagsLock = Object.assign({}, state.tagsLock)
        }
    },
    modules: {

    },
    getters: {
        blogs: state => {
            if (!state.blogsSearch.blogs.isEmpty()) {
                return state.blogsSearch.blogs
            } else if (state.blogsTags.blogsTagsMeta.isSomeTagActive) {
                return state.blogsTags.blogs
            } else return state.blogs.blogs
        },
        isResetActive: state => {
            if (state.blogsSearch.blogs.isEmpty()) {
                return false
            } else {
                return true
            }
        },
        isLocked: state => {
            return state.tagsLock
        }
    },
    actions: {
        initBlogs: (context, data) => {
            context.commit('blogs/setBlogs', data.length)
            context.dispatch('blogs/get', data.firstPage);
        },
        // ************************* RESET *****************************
        resetHandler: (context, data) => {
            context.commit('clearSearchVideos');
        },
        // ************************* RESET *****************************
        getFilteredData: (context, data) => {
            if (data.input) {
                axios.get(data.dataId + '/filter', {
                    params: {
                        input: data.input
                    }
                }).then((res) => {
                    const uri_part = data.dataId.replace('/', '_');
                    context.commit('handleFiltered_' + uri_part, res.data)
                }).catch(err => {
                    console.log(err)
                }).finally(res => {
                    context.commit('setTagsLock', { id: Number(data.input.id), state: false })
                })
            }
        },
        tagFilterSet: (context, id) => {
            //context.commit('setTagsLock', { id: Number(id), state: true })
            //if (!context.state.data.data[].videos.empty){
            context.dispatch('data/getByTag', Number(id))
            //}
            context.dispatch('blogsTags/get', Number(id))
        },
        tagFilterUnset: (context, id) => {
            if (!context.state.data.empty) {
                context.commit('data/removeVideosByTag', Number(id))
            }
            if (context.state.blogsTags.blogsTagsMeta.isSomeTagActive) {
                context.commit('blogsTags/removeBlogsByTag', Number(id))
            }
        },
        getVideos: (context, data) => {
            if (context.state.data.data[data.video_id]) {
                if (context.state.data.data[data.video_id].currentlyOnTagVideos === true) {
                    context.dispatch('data/getByTag', data)
                } else {
                    context.dispatch('data/get', data)
                }
            } else {
                context.dispatch('data/get', data)
            }
        },
        getSectionVideos: (context, id) => {
            if (context.state.sectionsData.currentlyOnTagVideos === true) {
                context.dispatch('sectionsData/getByTag', Number(id))
            } else {
                context.dispatch('sectionsData/get', Number(id));
            }
        },
        setId: (context, id) => {
            context.state.data.commit('setId', Number(id))
        },
        getBlogs: (context, id) => {
            if (!context.state.blogsTags.blogs.isEmpty()) {
                context.dispatch('blogs/get', id)
            } else context.dispatch('blogs/getDefault', id)
        }
    }
});
const app = new Vue({
    el: '#app',
    store,
    /*router,*/
    data: {},
    methods: {
        hideFlash: function (e) {
            e.target.parentElement.remove();
        },

        activeFormFilter(e) {
            var date_radio = document.getElementById("date-radio-label");
            var name_radio = document.getElementById("name-radio-label");
            var mail_radio = document.getElementById("mail-radio-label");
            e.target.classList.add("is-active");
            if (e.target.getAttribute('data-attr') === 'date-radio') {
                name_radio.classList.remove("is-active");
                mail_radio.classList.remove("is-active");
            } else if (e.target.getAttribute('data-attr') === 'name-radio') {
                date_radio.classList.remove("is-active");
                mail_radio.classList.remove("is-active");
            } else if (e.target.getAttribute('data-attr') === 'mail-radio') {
                date_radio.classList.remove("is-active");
                name_radio.classList.remove("is-active");
            }
        },
        showFormContent(e) {
            e.target.previousElementSibling.classList.toggle("is-active");
        },
        expandPostCard(e) {
            let show = e.target.closest('.show-options');
            let hide = show.nextElementSibling;
            show.style.display = "none";
            hide.style.display = "flex";
            e.target.closest('.card-header').nextElementSibling.style.display = "flex";
        },
        hidePostCard(e) {
            let hide = e.target.closest('.hide-options');
            let show = hide.previousElementSibling;
            show.style.display = "flex";
            hide.style.display = "none";
            e.target.closest('.card-header').nextElementSibling.style.display = "none";
        },
        activeFilter(e) {
            e.target.parentNode.classList.toggle("is-info");
        },
        activeCatFilter(e) {
            var cats = document.getElementsByClassName('cat-filter-tag');
            Array.from(cats).forEach(function (e) {
                e.classList.remove('is-info');
            });
            e.target.parentNode.classList.toggle("is-info");
            if (e.target.nextElementSibling.checked === true) {
                e.target.nextElementSibling.checked = false;
                e.target.nextElementSibling.disabled = true;
                e.target.parentNode.classList.toggle("is-info");
            }
        },
        activeTab(e) {
            let data = e.target.getAttribute('data-tab');
            let tab1 = document.getElementById('tab1');
            let tab2 = document.getElementById('tab2');
            let tab3 = document.getElementById('tab3');
            let butt1 = document.getElementById('tab_butt_1');
            let butt2 = document.getElementById('tab_butt_2');
            let butt3 = document.getElementById('tab_butt_3');

            if (data === "tab1") {
                tab1.classList.add('is-active');
                tab2.classList.remove('is-active');
                tab3.classList.remove('is-active');
                butt1.classList.add('is-active');
                butt2.classList.remove('is-active');
                butt3.classList.remove('is-active');
            }
            if (data === "tab2") {
                tab2.classList.add('is-active');
                tab1.classList.remove('is-active');
                tab3.classList.remove('is-active');
                butt1.classList.remove('is-active');
                butt3.classList.remove('is-active');
                butt2.classList.add('is-active');
            }
            if (data === "tab3") {
                tab3.classList.add('is-active');
                tab1.classList.remove('is-active');
                tab2.classList.remove('is-active');
                butt1.classList.remove('is-active');
                butt2.classList.remove('is-active');
                butt3.classList.add('is-active');
            }
        },
    }
});
