$(document).ready(function() {
    addMarkers();
    function addMarkers() {
        if ($(window).width() >= 768) {
            const regions = $(".distributor-map svg g");
            console.log("regions = " + regions);
            for (let i = 0; i < regions.length; i++) {
                if (
                    regions[i].classList.length !== 0 &&
                    regions[i].classList[0] !== "static"
                ) {
                    console.log("index = " + regions[i]);
                    const thisPositionTop =
                        $(regions[i])
                            .find("g")
                            .offset().top -
                        $(regions[0])
                            .parents(".distributor-map")
                            .offset().top;
                    const thisPositionLeft =
                        $(regions[i])
                            .find("g")
                            .offset().left -
                        $(regions[0])
                            .parents(".distributor-map")
                            .offset().left;
                    var thisName = $(regions[i]).attr("class");
                    var tableBlock = $(".distributor-tab-col ." + thisName);
                    $(".distributor-map").append(
                        '<div data-id="' +
                            regions[i].classList[0] +
                            '" class="marker marker-map-mod" style="top:' +
                            (thisPositionTop - 40) +
                            "px;left: " +
                            (thisPositionLeft + 5) +
                            'px;"></div>'
                    );
                }
            }
        }
    }
    //* ОБРАБОТКА НАЖАТИЯ ПО МЕТКЕ
    $(document).on("click", ".marker", function(e) {
        e.stopPropagation();
        if ($(window).width() > 767) {
            const region = $("." + $(this).attr("data-id"))[0];
            if (!$(region).hasClass("static")) {
                var thisPositionTop =
                    $(region)
                        .find("g")
                        .offset().top -
                    $(region)
                        .parents(".distributor-map")
                        .offset().top;
                var thisPositionLeft =
                    $(region)
                        .find("g")
                        .offset().left -
                    $(region)
                        .parents(".distributor-map")
                        .offset().left;
                var thisName = $(region).attr("class");
                var tableBlock = $(".distributor-tab-col ." + thisName);
                if ($(".distributor-map").hasClass("demo")) {
                    tableBlock = $(".distributor-tab-col .city_20");
                }
                var tableImgName = tableBlock
                    .next()
                    .find(".distributor-tab__info_img_name")
                    .html();
                var tablePhone = tableBlock
                    .next()
                    .find(".distributor-tab__info_phone")
                    .html();
                var tableMail = tableBlock
                    .next()
                    .find(".distributor-tab__info_mail")
                    .html();
                var tablLinkText = $(".distributor-map-hidden-block").data(
                    "text"
                );
                $(".distributor-map-hidden-block").empty();
                $(".distributor-map-hidden-block").removeClass(
                    "city_3 city_22"
                );
                if ($(region).hasClass("city_3")) {
                    $(".distributor-map-hidden-block").addClass("city_3");
                } else if ($(region).hasClass("city_22")) {
                    $(".distributor-map-hidden-block").addClass("city_22");
                }
                $(".distributor-map-hidden-block").css({
                    top: thisPositionTop,
                    left: thisPositionLeft
                });
                $(".distributor-map-hidden-block").append(
                    '<div class="triangle"></div>' +
                        /*'<div class="distributor-tab__info_img_name">'+ tableImgName +'</div>' +
                    '<div class="distributor-tab__info_phone">'+ tablePhone +'</div>' +
                    '<div class="distributor-tab__info_mail">'+ tableMail +'</div>' +*/
                        '<div class="distributor-tab__info_link"><span id="' +
                        thisName +
                        '" ' +
                        'scroll="' +
                        thisName +
                        '">' +
                        tablLinkText +
                        "</span></div>"
                );
                $(".distributor-map-hidden-block").addClass("visible");
            }
        }
    });
    $(document).on("click", ".expert-map svg g", function() {
        if (!$(this).hasClass("static")) {
            var thisName = $(this).attr("class");
            $(".expert-form-city .hidden-select__items li").each(function() {
                var thisId = $(this).data("id");
                if (thisName === thisId) {
                    $(this)
                        .parents(".hidden-select")
                        .find('input[type="text"]')
                        .val($(this).text());
                    $(this)
                        .parents(".hidden-select")
                        .find('input[type="hidden"]')
                        .val(thisId);
                    if ($(window).width() < 768) {
                        $("body, html").animate(
                            { scrollTop: $(".expert-calc").offset().top },
                            500
                        );
                    }
                }
            });
        }
    });
});
