var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.regs)?_c('div',{staticClass:"col-12 distributor-tab-col",attrs:{"id":"distributor-tab-col"}},_vm._l((_vm.regs),function(region,i){return _c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
            el: '#dist-' + i,
            offset: _vm.zoomValue
        }),expression:"{\n            el: '#dist-' + i,\n            offset: zoomValue\n        }"}],key:i,class:{
            'distributor-tab': true,
            active: _vm.activeIds.includes(region.id) === true ? true : false
        },attrs:{"id":'reg-' + i}},[_c('div',{class:'distributor-tab__title bg-' +
                    _vm.formBg(i) +
                    ' city_' +
                    region.city_id,attrs:{"id":'dist-' + i},on:{"click":function($event){return _vm.loadTab(i, region.id)}}},[_vm._v("\n            "+_vm._s(_vm.locale === "ua" ? region.name : region.name_ru)+"\n        ")]),_vm._v(" "),(region.reg && _vm.locale === 'ua')?_c('div',{staticClass:"distributor-tab__hidden"},[(region.dist)?_c('div',{staticClass:"distributor-tab__table"},[_c('table',[_vm._m(0,true),_vm._v(" "),_c('tbody',{staticClass:"slider-mob"},_vm._l((region.dist),function(dist,i){return _c('tr',{key:i},[_c('td',[_c('div',{staticClass:"user"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(dist.name))])]),_vm._v(" "),_c('td',[_c('div',{staticClass:"marker"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(dist.address))])]),_vm._v(" "),_c('td',[_c('div',{staticClass:"phone"}),_vm._v(" "),_c('span',[_c('i',_vm._l((dist.phones.split(
                                                ','
                                            )),function(phone){return _c('p',{key:phone.trim(),staticClass:"tel"},[_c('a',{attrs:{"href":'tel:' + phone.trim()}},[_vm._v(_vm._s(phone.trim()))])])}),0)])]),_vm._v(" "),_c('td',[_c('div',{staticClass:"map"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(dist.regions))])])])}),0)])]):_vm._e()]):_vm._e(),_vm._v(" "),(region.reg && _vm.locale === 'ru')?_c('div',{staticClass:"distributor-tab__hidden"},[(region.dist)?_c('div',{staticClass:"distributor-tab__table"},[_c('table',[_vm._m(1,true),_vm._v(" "),_c('tbody',{staticClass:"slider-mob"},_vm._l((region.dist),function(dist,i){return _c('tr',{key:i},[_c('td',[_c('div',{staticClass:"user"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(dist.name_ru))])]),_vm._v(" "),_c('td',[_c('div',{staticClass:"marker"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(dist.address_ru))])]),_vm._v(" "),_c('td',[_c('div',{staticClass:"phone"}),_vm._v(" "),_c('span',[_c('i',[_c('p',{staticClass:"tel"},[_c('a',{attrs:{"href":'tel:' +
                                                        dist.phones.split(
                                                            ','
                                                        )[0]}},[_vm._v(_vm._s(dist.phones))])])])])]),_vm._v(" "),_c('td',[_c('div',{staticClass:"map"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(dist.regions_ru))])])])}),0)])]):_vm._e()]):_vm._e()])}),0):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Назва компанії")]),_vm._v(" "),_c('th',[_vm._v("Адреса центрального офісу")]),_vm._v(" "),_c('th',[_vm._v("Телефон")]),_vm._v(" "),_c('th',[_vm._v("Електронна пошта")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Название компании")]),_vm._v(" "),_c('th',[_vm._v("Адрес центрального офиса")]),_vm._v(" "),_c('th',[_vm._v("Телефон")]),_vm._v(" "),_c('th',[_vm._v("Электронная почта")])])])}]

export { render, staticRenderFns }