document.body.addEventListener('DOMContentLoaded', slidersInit)
//document.onload(slidersInit)
const autoplaySpeed = 3500
slidersInit()
function slidersInit() {
    $('.sliders').show()
    $('#preload').hide()    
    sliderMain();

    function sliderMain() {
        var mainSlider = $('.first-slider')
        if ($(mainSlider).children().length > 1) {
            $(mainSlider).owlCarousel({
                responsiveClass: true,
                items: 1,
                loop: true,
                dots: true,
                nav: true,
                navText:[],
                slideBy: 1,
                autoplay: true,
                smartSpeed: 650,
                autoplayTimeout: autoplaySpeed,
                autoWidth:false,
                onInitialize: function() {
                    $(mainSlider).on('initialized.owl.carousel', function(event) {
                        // window.dispatchEvent(new Event('resize'))
                    })
                },
                onResize: function() {

                }
            })
        }
    }

    sliderInnovation();

    function sliderInnovation() {
        var innovationSlider = $('.home-innovation-slider')
        if(window.innerWidth >= 768) {
            if ($(innovationSlider).children().length > 3) {
                $(innovationSlider).owlCarousel({
                    responsiveClass: true,
                    items: 3,
                    loop: true,
                    dots: false,
                    nav: true,
                    navText:[],
                    autoplay: true,
                    slideBy: 1,
                    smartSpeed: 350,
                     /* autoplayTimeout: autoplaySpeed, */ 
                    autoWidth:false,
                    onInitialize: function() {
                        $(innovationSlider).on('initialized.owl.carousel', function(event) {
                             window.dispatchEvent(new Event('resize'))
                        })
                    },
                    onResize: function() {

                    }
                })


            }
        } else {
            $(innovationSlider).trigger('destroy.owl.carousel');
        }
    }

    sliderDistributor();
    function sliderDistributor() {
        var distributorSlider = $('.distributor-slider')
        if(window.innerWidth >= 768) {
            if ($(distributorSlider).children().length > 3) {
                $(distributorSlider).owlCarousel({
                    responsiveClass: true,
                    items: 3,
                    loop: true,
                    dots: true,
                    autoplay: true,
                    nav: true,
                    navText:[],
                    slideBy: 3,
                    smartSpeed: 500,
                    /* autoplayTimeout: autoplaySpeed, */
                    autoWidth:false,
                    responsive : {
                        0 : {
                            items: 1,
                            slideBy: 1
                        },

                        768 : {
                            items: 3
                        }
                    },
                    onInitialize: function() {
                        $(distributorSlider).on('initialized.owl.carousel', function(event) {
                            window.dispatchEvent(new Event('resize'))
                        })
                    },
                    onResize: function() {

                    }
                })


            }
        } else {
            // $(distributorSlider).trigger('destroy.owl.carousel');
            if ($(distributorSlider).children().length > 1) {
                $(distributorSlider).owlCarousel({
                    responsiveClass: true,
                    items: 1,
                    loop: true,
                    dots: true,
                    nav: true,
                    navText:[],
                    autoplay: true,
                    slideBy: 1,
                    smartSpeed: 500,
                    /* autoplayTimeout: autoplaySpeed, */
                    autoWidth:false,
                    responsive : {
                        0 : {
                            items: 1,
                            slideBy: 1
                        },

                        768 : {
                            items: 3,
                        }
                 },

                    onInitialize: function() {
                        $(distributorSlider).on('initialized.owl.carousel', function(event) {
                            window.dispatchEvent(new Event('resize'))
                        })
                    },
                    onResize: function() {

                    }
                })


            }
        }
    }

    sliderDistributorBottom();
    function sliderDistributorBottom() {
        var distributorBottomSlider = $('.distributor-slider-bottom')
        if(window.innerWidth >= 768) {
            if ($(distributorBottomSlider).children().length > 4) {
                $(distributorBottomSlider).owlCarousel({
                    responsiveClass: true,
                    items: 4,
                    loop: true,
                    dots: true,
                    nav: true,
                    autoplay: true,
                    navText:[],
                    slideBy: 1,                    
                    smartSpeed: 500,
                    /* autoplayTimeout: autoplaySpeed, */
                    autoWidth:false,
                    responsive : {
                        0 : {
                            items: 2,
                            slideBy: 1
                        },
                        500 : {
                            items: 3,
                            slideBy: 1
                        },
                        768 : {
                            items: 4,
                            slideBy: 1
                        }
                    },
                    onInitialize: function() {
                        $(distributorBottomSlider).on('initialized.owl.carousel', function(event) {
                            window.dispatchEvent(new Event('resize'))
                        })
                    },
                    onResize: function() {

                    }
                })


            }
        } else {
            if ($(distributorBottomSlider).children().length > 2) {
                $(distributorBottomSlider).owlCarousel({
                    responsiveClass: true,
                    items: 4,
                    loop: true,
                    dots: true,
                    nav: true,
                    navText:[],
                    slideBy: 1,
                    autoplay: true,
                    /* autoplayTimeout: autoplaySpeed, */
                    smartSpeed: 500,
                    autoWidth:false,
                    responsive : {
                        0 : {
                            items: 2,
                            slideBy: 1
                        },
                        500 : {
                            items: 3,
                            slideBy: 1
                        },
                        768 : {
                            items: 4,
                            slideBy: 1
                        }
                    },
                    onInitialize: function() {
                        $(distributorBottomSlider).on('initialized.owl.carousel', function(event) {
                            window.dispatchEvent(new Event('resize'))
                        })
                    },
                    onResize: function() {

                    }
                })


            }
        }
    }

    sliderDistributorTablet();
    function sliderDistributorTablet() {
        var distributorTabletSlider = $('.distributor-tab__table .slider-mob')




        if(window.innerWidth < 768) {
            if ($(distributorTabletSlider).children().length > 1) {
                $(distributorTabletSlider).owlCarousel({
                    responsiveClass: true,
                    items: 1,
                    loop: false,
                    dots: true,
                    nav: true,
                    navText:[],
                    slideBy: 1,
                    autoplay: true,
                    /* autoplayTimeout: autoplaySpeed, */
                    smartSpeed: 600,
                    autoWidth:false,
                    onInitialize: function() {
                    }
                })

            }

        } else {
            $(distributorTabletSlider).trigger('destroy.owl.carousel');

        }
    }

    cardSlider();
    function cardSlider() {
        $('#preload').hide()
        $('.card-slider').show()
        var cardSlider = $('.card-slider')
        if ($(cardSlider).children().length > 1) {
            $(cardSlider).owlCarousel({
                responsiveClass: true,
                items: 1,
                loop: true,
                dots: true,
                nav: true,
                autoplay: true,
                autoplayTimeout: 4000,
                navText:[],
                slideBy: 1,
                smartSpeed: 650,
                autoWidth:false,


            })
        }
    }


    $(window).resize(function() {
        sliderMain()
        sliderInnovation()
        sliderDistributor()
        sliderDistributorBottom()
        sliderDistributorTablet()
        cardSlider()
    })
}
