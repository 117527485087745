$(document).ready(function() {
    var statusMenu = true;
    openMobMenuNew();
    mainMenu();
    infoHover();
    technologyHover();
    innovationHover();
    search();
    product();
    contactTabs();
    arrowUpScroll();
    arrowUpClick();
    expertsForm();
    cardTable();
    seotext();

    validateInput()
    function seotext(){
        $('#show_seo').on('click', function(e){
            e.preventDefault()
            e.stopPropagation()
                $('#seo_hidden').is(':visible') ? $('#seo_hidden').hide() : $('#seo_hidden').show()
                this.remove()
        })
    };
    function cardTable() {
        $(document).on('click','.page-card-table__title', function () {
            $(this).parent().toggleClass('active')

        })
    }
    function expertsForm() {
        $('.hidden-select input').click(function () {

            if($(this).parent().hasClass('active')) {
                $('.hidden-select').removeClass('active');
                $(this).parent().removeClass('active')
            } else {
                $('.hidden-select').removeClass('active');
                $(this).parent().addClass('active')
            }
        })
        $(document).on('click','.hidden-select__items ul li', function () {
            var thisName = $(this).html();
            var thisId = $(this).data('id');
            console.log(thisId)
            $(this).parents('.hidden-select').find('input[type="text"]').val(thisName)
            $(this).parents('.hidden-select').find('input[type="hidden"]').val(thisId)
            $('.hidden-select').removeClass('active');

        })
        $(document).on('click',function (e) {
            var div = $(".hidden-select");
            if (!div.is(e.target) && div.has(e.target).length === 0) {
                div.removeClass('active')
            }
        })
        // input-number
        $('.input-number input').keypress(function(e) {
            if (!(e.which==8 ||(e.which>47 && e.which<58))) return false;
        });
        $(document).on('click','.input-number span', function () {
            if (!$(this).hasClass('custom-plus') &&  !$(this).hasClass('custom-minus')){
                var val = $(this).parents('.input-number').find('input').val()
                if($(this).hasClass('plus')) {
                    val++;
                } else {
                    val--;
                    if(val < 2) {
                        val = 1
                    }
                }
                $(this).parents('.input-number').find('input').val(val)
            }


        })
        $('.input-number input').focusout(function () {
            if($(this).val() < 1) {
                $(this).val(1)
            }
        })
    }
    function contactTabs() {
        $(document).on('click','.contact-page__tab_list span',function () {
            const index = $(this).parent().index();
            const block = $('.contact-page__tab_item');
            $('.contact-page__tab_list ul li').removeClass('active')
            $(this).parent().addClass('active')
            $(block).removeClass('active');
            $(block[index]).addClass('active');
        })
    }

    function arrowUpScroll(){
        handleArrow()
        $(document).on('scroll', handleArrow)
        function handleArrow(){
             //const header = $('#header_')
             const arrow = $('#arrow-up')
             let top = $(window).height() - 250
             const footerMarginTop = $('#footer_').outerHeight(true) - $('#footer_').outerHeight()
             const footerTopVisible = $('#footer_').position().top + footerMarginTop
             const decorativeArrowOffset = 16
            // console.log('win height = '+ $(window).height() + ', arr top  = ' + (arrow.offset().top + decorativeArrowOffset)+ ', footer top = ' + footerTopVisible + ', footer margin = ' + footerMarginTop)
             if ((arrow.offset().top + decorativeArrowOffset) >= footerTopVisible){
                 //top -=
             }


             if ($(this).scrollTop() >= 300){
                 //arrow.css('top', top)
                 arrow.show()
             }else{
                 arrow.hide()
             }
        }
    }
    function arrowUpClick(){
        $('#arrow-up').on('click', function(){
            console.log('arrow clicked')
            $(document.documentElement).animate({
                scrollTop: $('#header_').offset().top
            }, 1000)
        })
    };
    function mainMenu() {
        $('.main-menu ul li').each(function () {
            $(this).attr('level',0)
            if($(this).find('.sub-menu').length) {
                $(this).addClass('has-list');
                $(this).append('<i class="d-md-none menu-mob-button"></i>')
            }
            if($(this).parent().parent().hasClass('sub-menu')) {
                var newLevel = +($(this).parent().parent().parent().attr('level'))
                $(this).attr('level', newLevel + 1)
            }
        })
        $('.main-menu ul li').each(function () {
          var thisLevel = $(this).attr('level');
          $(this).addClass('level-' + thisLevel)
            $(this).removeAttr('level')
        })

        $(document).on('click','.menu-mob-button', function () {
            if($(this).parent().hasClass('show')) {
                $(this).parent().removeClass('show')
                if($(this).parent().hasClass('level-0')) {
                    $(this).parent().find('li').removeClass('show')
                }
            } else {
                $(this).parent().addClass('show')
            }
        })
        $(document).on('click','.main-menu-lang li', function (e) {
            if($(this).parents('.main-menu-lang').hasClass('show-lang')) {
                $('.main-menu-lang li').removeClass('active');
                $(this).addClass('active');
                $(this).parents('.main-menu-lang').removeClass('show-lang');
            } else {

                $(this).parents('.main-menu-lang').addClass('show-lang');
            }
        })
        $(document).on('click','.main-menu-lang li.active a', function (e) {
            e.preventDefault();
        })

    }

    function  infoHover() {
        $('.home-info-right__item_title').hover(function () {
            $(this).parents('.home-info-right__item').addClass('hover')
        }, function () {
            $(this).parents('.home-info-right__item').removeClass('hover')
        })

        $('.home-info-left__item_link').hover(function () {
            $(this).parents('.home-info-left__item').addClass('hover')
        }, function () {
            $(this).parents('.home-info-left__item').removeClass('hover')
        })
    }

    function  technologyHover() {
        $('.home-technology__item_link, .technology-app__item_link').hover(function () {
            $(this).parent().addClass('hover')
        }, function () {
            $(this).parent().removeClass('hover')
        })
        $('.technology-schema__item_link').hover(function () {
            $(this).parent().addClass('hover')
        }, function () {
            $(this).parent().removeClass('hover')
        })

    }

    function innovationHover() {
        $('.home-innovation__item_link a').hover(function () {
            $(this).parents('.home-innovation__item').addClass('hover')
        }, function () {
            $(this).parents('.home-innovation__item').removeClass('hover')
        })
    }

    function search() {

        $(document).on('click','.search-ico', function () {
            $('.hidden-search').addClass('active')
            $('.hidden-search').find('input').focus()
            setTimeout(function () {
                $('.hidden-search').addClass('in')
            },400)
        })

        $(document).on('click',function (e) {
            var div = $(".hidden-search");
            if(div.hasClass('in')) {
                if (!div.is(e.target) && div.has(e.target).length === 0) {
                    div.removeClass('active')
                    div.removeClass('in')
                }
            }
        })
        $('.main-menu-search input').focus(function () {
            $('.main-menu-search').addClass('focus')
        })
        $('.main-menu-search input').focusout(function () {
            $('.main-menu-search').removeClass('focus')
        })
    }
    const intervals = {}
    function product() {
        var animationTime = 800;
        var hoverDelay = 3000;
        $('.product-item__main').each(function () {
            $(this).attr('animation',0)
        })

        $('.product-item__main').hover(function (e) {
            if($(window).width()>767 && !($(this).parent().is('.static, .empty'))) {
                var this_ = $(this)
                if (!intervals[this_.data('key')]){
                    intervals[this_.data('key')] = {}
                }else {
                    clearInterval(intervals[this_.data('key')]['handler'])
                }
                var animationStatus = +(this_.attr('animation'));
                if(animationStatus === 0) {
                    this_.attr('animation',1)
                    this_.addClass('hover');
                    setTimeout(function () {
                        this_.attr('animation',0)
                        $('body').trigger('mousemove');
                    },animationTime)
                }

                $(this).addClass('hover')
            }
        }, function () {
            var this_ = $(this)

            if (!intervals[this_.data('key')]){
                intervals[this_.data('key')] = {}
            }
            intervals[this_.data('key')]['handler'] = setTimeout(e => this_.removeClass('hover'), hoverDelay)
        })
        // $('.product-item__main').hover(function () {
        //     console.log('in')
        //     $(this).parent().addClass('hover')
        // }, function () {
        //     console.log('out')
        //     $(this).parent().removeClass('hover')
        // })


        $('.product-item').mousemove(function () {

            // $('.product-item').removeClass('hover')
            // var this_ = $(this);
            // var animationStatus = +(this_.attr('animation'));
            //  if(animationStatus === 0) {
            //     this_.attr('animation',1)
            //     this_.addClass('hover');
            //     setTimeout(function () {
            //         this_.attr('animation',0)
            //         console.log('timeout');
            //     },animationTime)
            // }
        })
        $('.product-item').mouseout(function () {
            // var this_ = $(this);
            // var animationStatus = +(this_.attr('animation'));
            // if(animationStatus === 0) {
            //     $(this).removeClass('hover')
            // } else {
            //     interval_ = setInterval(function () {
            //         console.log(animationStatus)
            //         this_.removeClass('hover')
            //         clearInterval(interval_)
            //
            //     },100)
            // }

            // console.log('leave')
            // var this_ = $(this);
            // var animationStatus = +(this_.attr('animation'));
            //
            // if(animationStatus === 0) {
            //
            // } else if (animationStatus === 1) {
            //     interval = setInterval(function () {
            //         console.log('interval')
            //         clearInterval(interval)
            //         this_.removeClass('hover')
            //     },500)
            // }

            // if(animationStatus === 0) {
            //     this_.removeClass('hover')
            // } else if(animationStatus === 1) {
            //     setTimeout(function () {
            //
            //     },animationTime)
            // }
        })

        // $('.product-item').hover(function () {
        //     var this_ = $(this);
        //     console.log(this_.attr('animation'))
        //     if(this_.attr('animation') === undefined || this_.attr('animation') === false) {
        //         this_.addClass('hover');
        //         this_.attr('animation',true);
        //
        //         setTimeout(function () {
        //             this_.attr('animation',false);
        //         },animationTime)
        //     }
        //
        //
        //
        // })
        // $('.product-item').mouseleave(function () {
        //
        //     if(($(this).attr('animation'))) {
        //         // console.log('xxxxxxxxxxx')
        //         $(this).removeClass('hover')
        //     }
        // })
    }

    function toogleMenu() {
        if(statusMenu) {
            statusMenu = false;
            if($('body').find('.overlay-menu').length < 1) {
                $('.main-menu-col').parent().append('<div class="overlay-menu" style="display: none;"></div>')
            }
            $('.overlay-menu').fadeIn(400);
            $('body').addClass('body-fixed show-block-mobile');
            $('.div-menu').addClass('active')
        } else {
            $('.main-menu .has-list').removeClass('show');
            $('.main-menu-lang').removeClass('show-lang');
            $('.overlay-menu').fadeOut(300);
            $('body').removeClass('show-block-mobile');
            $('.div-menu').removeClass('active');
            setTimeout(function () {
                $('body').removeClass('body-fixed');
                $('.overlay-menu').remove()
                statusMenu = true;
            },510);
        }
    }

    function openMobMenuNew() {
        $('.div-menu').click(function () {
            toogleMenu();
        })
        $(document).on('click', '.overlay-menu', function () {
            toogleMenu();
        })
        $(document).on('click', '.main-menu-close', function () {
            toogleMenu();
        })
    }

























































    $(window).resize(function () {

        if(window.innerWidth>=992){

        }
        if(window.innerWidth>=768){
            $('body').removeClass('body-fixed show-block-mobile');
            $('.div-menu').removeClass('active');


        } else {

        }



    })


    function validateInput(){

        $('#form-adjuvants').submit(function (e){
            //e.preventDefault()
            if ($('.error-custom').text().length > 0){
                e.preventDefault()
            }
        })
        /* $('.custom-input').change(function (){
            const control = $($(this).siblings('.custom-controls')[0])
            const min = Number(control.attr('data-min'))
            const max = Number(control.attr('data-max'))
            const currentValue = Number($(this).val())
            const error = control.next()
            if (currentValue < min || currentValue > max){

                error.text(error.attr('data-error-msg'))
               // console.log('error msg + ' + error.attr('data-error-msg'))
            }else{
                error.empty()
            }
           // console.log(`min = ${min}, max = ${max}, current value = ${currentValue}`)
        })  */
    }

})
