<template>
    <div
        v-if="regs"
        id="distributor-tab-col"
        class="col-12 distributor-tab-col"
    >
        <div
            :id="'reg-' + i"
            v-for="(region, i) in regs"
            :key="i"
            v-scroll-to="{
                el: '#dist-' + i,
                offset: zoomValue
            }"
            :class="{
                'distributor-tab': true,
                active: activeIds.includes(region.id) === true ? true : false
            }"
        >
            <div
                :id="'dist-' + i"
                @click="loadTab(i, region.id)"
                :class="
                    'distributor-tab__title bg-' +
                        formBg(i) +
                        ' city_' +
                        region.city_id
                "
            >
                {{ locale === "ua" ? region.name : region.name_ru }}
            </div>

            <div
                v-if="region.reg && locale === 'ua'"
                class="distributor-tab__hidden"
            >
                <!-- <div class="distributor-tab__region_title">
                    Регіональний представник
                </div>-->
                <!-- <div class="distributor-tab__info">
                    <div class="row align-items-center">
                        <div class="col-12 col-md-auto">
                            <div class="distributor-tab__info_img_name">
                                <div class="distributor-tab__info_img">
                                    <img :src="region.reg.preview" alt="" />
                                </div>
                                <div class="distributor-tab__info_name">
                                    {{ region.reg.name }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md text-md-center">
                            <div class="distributor-tab__info_phone">
                                <a :href="'tel:' + region.reg.phone">{{
                                    region.reg.phone
                                }}</a>
                            </div>
                        </div>
                        <div class="col-12 col-md-auto">
                            <div class="distributor-tab__info_mail">
                                <a :href="'mailto:' + region.reg.mail">{{
                                    region.reg.mail
                                }}</a>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div v-if="!region.dist" id="preloader" class="distributor-tab__table preloader-visible"></div> -->
                <div v-if="region.dist" class="distributor-tab__table">
                    <table>
                        <thead>
                            <tr>
                                <th>Назва компанії</th>
                                <th>Адреса центрального офісу</th>
                                <th>Телефон</th>
                                <th>Електронна пошта</th>
                            </tr>
                        </thead>
                        <tbody class="slider-mob">
                            <tr v-for="(dist, i) in region.dist" :key="i">
                                <td>
                                    <div class="user"></div>
                                    <span>{{ dist.name }}</span>
                                </td>
                                <td>
                                    <div class="marker"></div>
                                    <span>{{ dist.address }}</span>
                                </td>
                                <td>
                                    <div class="phone"></div>
                                    <span>
                                        <i>
                                            <!--   <p class="tel">
                                                <a
                                                    :href="
                                                        'tel:' +
                                                            dist.phones.split(
                                                                ','
                                                            )[0]
                                                    "
                                                    >{{ dist.phones }}</a
                                                >
                                            </p> -->
                                            <p
                                                class="tel"
                                                v-for="phone in dist.phones.split(
                                                    ','
                                                )"
                                                :key="phone.trim()"
                                            >
                                                <a
                                                    :href="
                                                        'tel:' + phone.trim()
                                                    "
                                                    >{{ phone.trim() }}</a
                                                >
                                            </p>
                                        </i>
                                    </span>
                                </td>
                                <td>
                                    <div class="map"></div>
                                    <span>{{ dist.regions }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div
                v-if="region.reg && locale === 'ru'"
                class="distributor-tab__hidden"
            >
                <!-- <div class="distributor-tab__region_title">
                    Региональный представитель
                </div>-->
                <!--<div class="distributor-tab__info">
                    <div class="row align-items-center">
                        <div class="col-12 col-md-auto">
                            <div class="distributor-tab__info_img_name">
                                <div class="distributor-tab__info_img">
                                    <img :src="region.reg.preview" alt="" />
                                </div>
                                <div class="distributor-tab__info_name">
                                    {{ region.reg.name_ru }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md text-md-center">
                            <div class="distributor-tab__info_phone">
                                <a :href="'tel:' + region.reg.phone">{{
                                    region.reg.phone
                                }}</a>
                            </div>
                        </div>
                        <div class="col-12 col-md-auto">
                            <div class="distributor-tab__info_mail">
                                <a :href="'mailto:' + region.reg.mail">{{
                                    region.reg.mail
                                }}</a>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div v-if="!region.dist" id="preloader" class="distributor-tab__table preloader-visible"></div> -->
                <div v-if="region.dist" class="distributor-tab__table">
                    <table>
                        <thead>
                            <tr>
                                <th>Название компании</th>
                                <th>Адрес центрального офиса</th>
                                <th>Телефон</th>
                                <th>Электронная почта</th>
                            </tr>
                        </thead>
                        <tbody class="slider-mob">
                            <tr v-for="(dist, i) in region.dist" :key="i">
                                <td>
                                    <div class="user"></div>
                                    <span>{{ dist.name_ru }}</span>
                                </td>
                                <td>
                                    <div class="marker"></div>
                                    <span>{{ dist.address_ru }}</span>
                                </td>
                                <td>
                                    <div class="phone"></div>
                                    <span>
                                        <i>
                                            <p class="tel">
                                                <a
                                                    :href="
                                                        'tel:' +
                                                            dist.phones.split(
                                                                ','
                                                            )[0]
                                                    "
                                                    >{{ dist.phones }}</a
                                                >
                                            </p>
                                        </i>
                                    </span>
                                </td>
                                <td>
                                    <div class="map"></div>
                                    <span>{{ dist.regions_ru }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as axios from "axios";
export default {
    props: {
        regions: {
            type: Array,
            required: true
        },
        locale: {
            type: String,
            required: true
        }
    },
    mounted() {
        //* ОБРАБОТКА НАЖАТИЯ ПО ОБЛАСТИ
        const locale = this.locale;
        if (locale == "ru") {
            //console.log('locale = ' + locale + ',success!')
            $(".distributor-map-hidden-block").data(
                "text",
                "Пересмотреть дистрибтюторов"
            );
        }
        $(document).on("click", ".distributor-map svg g", function() {
            if ($(window).width() > 767) {
                if (!$(this).hasClass("static")) {
                    console.log(this);
                    var thisPositionTop =
                        $(this)
                            .find("g")
                            .offset().top -
                        $(this)
                            .parents(".distributor-map")
                            .offset().top;
                    var thisPositionLeft =
                        $(this)
                            .find("g")
                            .offset().left -
                        $(this)
                            .parents(".distributor-map")
                            .offset().left;
                    var thisName = $(this).attr("class");
                    var tableBlock = $(".distributor-tab-col ." + thisName);
                    if ($(".distributor-map").hasClass("demo")) {
                        tableBlock = $(".distributor-tab-col .city_20");
                    }
                    var tableImgName = tableBlock
                        .next()
                        .find(".distributor-tab__info_img_name")
                        .html();
                    var tablePhone = tableBlock
                        .next()
                        .find(".distributor-tab__info_phone")
                        .html();
                    var tableMail = tableBlock
                        .next()
                        .find(".distributor-tab__info_mail")
                        .html();
                    var tablLinkText = $(".distributor-map-hidden-block").data(
                        "text"
                    );
                    $(".distributor-map-hidden-block").empty();
                    $(".distributor-map-hidden-block").removeClass(
                        "city_3 city_22"
                    );
                    if ($(this).hasClass("city_3")) {
                        $(".distributor-map-hidden-block").addClass("city_3");
                    } else if ($(this).hasClass("city_22")) {
                        $(".distributor-map-hidden-block").addClass("city_22");
                    }
                    $(".distributor-map-hidden-block").css({
                        top: thisPositionTop,
                        left: thisPositionLeft
                    });
                    $(".distributor-map-hidden-block").addClass("visible");
                    $(".distributor-map-hidden-block").append(
                        '<div class="triangle"></div>' +
                            /*   '<div class="distributor-tab__info_img_name">' +
                            tableImgName +
                            "</div>" +
                            '<div class="distributor-tab__info_phone">' +
                            tablePhone +
                            "</div>" +
                            '<div class="distributor-tab__info_mail">' +
                            tableMail +
                            "</div>" +*/
                            '<div class="distributor-tab__info_link"><span id="' +
                            thisName +
                            '" ' +
                            'scroll="' +
                            thisName +
                            '">' +
                            tablLinkText +
                            "</span></div>"
                    );
                }
            }
        });

        $(document).on("click", ".distributor-tab__info_link span", function(
            e
        ) {
            var scrollClass = $(e.currentTarget).attr("scroll");
            console.log(
                "scrll class = " + e.currentTarget.getAttribute("scroll")
            );

            document
                .querySelector(".distributor-tab-col ." + scrollClass)
                .click();
            console.log("clicked");
        });
        $(document).on("click", ".distributor-tab__info_link span", function() {
            var scrollClass = $(this).attr("scroll");
            $(".distributor-tab").removeClass("active");
            $(".distributor-tab-col ." + scrollClass)
                .parent()
                .addClass("active");
            var scrollTo = $(".distributor-tab-col ." + scrollClass).offset()
                .top;
            $("body, html").animate({ scrollTop: scrollTo }, 500, function() {
                $(".distributor-map-hidden-block").removeClass("visible");
            });
        });
    },
    methods: {
        formBg(index) {
            switch (Number(index)) {
                case 0:
                case 1:
                case 2:
                    return 1;
                case 3:
                case 4:
                case 5:
                case 6:
                    return 2;
                case 7:
                case 8:
                    return 3;
                case 9:
                case 10:
                    return 4;
                case 11:
                case 12:
                case 13:
                case 14:
                    return 5;
                case 15:
                case 16:
                case 17:
                    return 6;
                case 18:
                case 19:
                    return 7;
                case 20:
                    return 9;
                default:
                    return 8;
            }
        },
        isSentAlready(id) {
            if (this.tabsFilledIds.includes(id)) return true;
            return false;
        },
        loadTab(regionIndex, id) {
            // Закрываем последнюю активную вкладку, если такова имеется
            const clickedId = this.activeIds.pop();
            // Открываем новую (либо просто закрываем старую)
            Number(id) == clickedId ? false : this.activeIds.push(Number(id));
            if (!this.isSentAlready(Number(id))) {
                axios
                    .get("/distributors-tab-handler/getTabData", {
                        params: {
                            id: Number(id)
                        }
                    })
                    .then(res => {
                        setTimeout(
                            data => {
                                if (data.length > 0) {
                                    this.regs[regionIndex].dist = data;
                                    this.tabsFilledIds.push(Number(id));
                                }
                                this.regs = Object.assign({}, this.regs);
                            },
                            300,
                            res.data.dist
                        );
                        setTimeout(() => this.initCarousel(), 301);
                    })
                    .catch(e => {});
            }
        },
        initCarousel() {
            var distributorTabletSlider = $(".slider-mob");
            console.log(distributorTabletSlider);
            if (window.innerWidth < 768) {
                if ($(distributorTabletSlider).children().length > 1) {
                    $(distributorTabletSlider).owlCarousel({
                        responsiveClass: true,
                        items: 1,
                        loop: false,
                        dots: true,
                        nav: true,
                        navText: [],
                        slideBy: 1,
                        autoplay: true,
                        /* autoplayTimeout: autoplaySpeed, */
                        smartSpeed: 600,
                        autoWidth: false,
                        onInitialize: function() {}
                    });
                }
            } else {
                $(distributorTabletSlider).trigger("destroy.owl.carousel");
            }
        }
    },
    data: function() {
        return {
            regs: this.regions,
            tabsFilledIds: [],
            activeIds: [],
            zoomOffset:
                -(
                    $(window).height() -
                    $(window).height() * Number($(document.body).css("zoom"))
                ) / 2,
            docHeight: $(window).height(),
            zoomHeight:
                -(
                    $(window).height() -
                    $(window).height() * Number($(document.body).css("zoom"))
                ) / 2,
            actualOffset:
                ($(window).height() -
                    $(window).height() * Number($(document.body).css("zoom"))) *
                (Number($(document.body).css("zoom")) * 4)
        };
    },
    computed: {
        zoomValue() {
            return /* this.zoomOffset - */ -(
                150 +
                (150 - 150 * Number($(document.body).css("zoom"))) * 4 +
                this.actualOffset
            );
        }
    }
};
</script>

<style>
#preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    overflow: visible;
    background: #fbfbfb
        url("//cdnjs.cloudflare.com/ajax/libs/file-uploader/3.7.0/processing.gif")
        no-repeat center center;
}
.preloader-visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear;
}

.preloader-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
}
</style>
